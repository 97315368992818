import { graphql, PageProps } from "gatsby"
import Page from "@components/Error"

export type Props = PageProps<GatsbyTypes.PageErrorQuery, GatsbyTypes.PageErrorQueryVariables>

export const query = graphql`
  query PageError {
    page: sanityPageError {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 5 })
    }
  }
`

export { Head } from "@components/Head"

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component

import { memo } from "react"
import { Container, Box, Heading, Button } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { useCore } from "@app/hooks/useCore"
import { CustomLink } from "@components/Link"

import type { Props } from "@app/pages/404"
import type { PageProps } from "@root/types/global"

const Error: React.FC<PageProps<Props>> = ({ page }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const { getContent } = useContent()
  const content = getContent({ content: page?.content })

  if (!isBrowser) return null

  return (
    <Container py={27}>
      <Box as="section" textAlign="center">
        <Heading as="h1" mb={4}>
          {page?.title}
        </Heading>
        <Box mb={8}>{content}</Box>
        <CustomLink to="/">
          <Button>Return Home</Button>
        </CustomLink>
      </Box>
    </Container>
  )
}

export default memo(Error)
